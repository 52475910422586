import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})

export class JobTypeService {

  constructor(private http: HttpBaseService) { }

  getJobType(offset: string, status: string, limit?: any) {

    offset = offset ? '?offset=' + offset : '?offset=0';
    limit = limit ? '&limit=' + limit : '&limit=0';
    status = status ? '&status='+ status: '';
    return this.http.get('jobCategory/global' + offset + limit + status);
  }

  getFirmJobType(firmId: string, all = false, offset?: any, limit?: any) {

    offset = offset ? '?offset=' + offset : '?offset=0';
    limit = limit ? '&limit=' + limit : '&limit=0';
    let allParam = all ? '&all=true' : '&all=false';

    return this.http.get('jobCategory/firm/'+firmId + offset + limit + allParam);
  }

  getSingleDynamicRoleCriteria(id: string) {
    return this.http.get('jobCategory/' + id);
  }

  createJobType(credential: any) {
    return this.http.post('jobCategory', credential);
  }

  updateJobType(id: string, credential: any) {
    return this.http.put('jobCategory/' + id, credential);
  }

  archiveJobType(id: string) {
    return this.http.put('jobCategory/archive/' + id);
  }

  unarchiveJobType(id: string) {
    return this.http.put('jobCategory/unarchive/' + id);
  }

  deleteJobType(id: string) {
    return this.http.delete('jobCategory/' + id);
  }

  updateStatus(id: string, body: any) {
    return this.http.put('jobCategory/updateStatus/' + id, body);
  }

  updateReviewerJobTypes(payload: any) {
    return this.http.put('jobCategory/reviewerCredentials',payload);
  }

  getReviewerCredentials(id: string) {
    return this.http.get('jobCategory/reviewerCredentials/'+id);
  }

  getGlobalJobTypeListing() {
    return this.http.get('jobCategory/globalListDropdown');
  }

  getGlobalJobTypeListingByFirmId(firmId: string) {
    return this.http.get('jobCategory/firm/'+firmId+'/globalListDropdown');
  }
  
}
